
.footer-divs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.logo-circle {
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  border-radius: 50%; /* Make it circular */
}

.your-paragraph-class {
  transition: transform 0.3s ease-in-out;
  font-family: 'Times New Roman', serif !important; /* Apply the new font */
}

.your-paragraph-class:hover {
  transform: translateY(-3px);
}

a {
  font-family: 'Times New Roman', serif !important; /* Apply the new font */
}

a:hover {
  /* Styles for when the link is hovered */
  color: #150a42; /* Change color on hover */
  text-decoration: underline; /* Add underline on hover */
  transform: translateY(-3px);
  /* Add other styles as needed */
}

.text-white {
  color: white !important;
}

a.text-white:hover{
  color:#150a42;
  transform: translateY(-3px);
}

.text-center {
  text-align: center;
}

.bg-dark {
  background-color: #343a40 !important;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.modal-content {
  font-family: 'Times New Roman', Times, serif;
  background-color: #fff;
  color: #000;
}

.modal-header, .modal-footer {
  border: none;
}

@media (max-width: 768px) {
  .logo {
    margin-bottom: 0; /* Remove margin-bottom on smaller screens */
  }
}
