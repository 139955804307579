@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap'); /* Replace with actual font if available */

.home-picture {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; /* Increase the height here */
  font-family: 'Roboto', sans-serif; /* Apply the new font */
}

.home-picture::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Darken the background image */
  z-index: 1; /* Ensure the shadow is behind the text */
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
  text-align: center;
  padding: 0 10px; /* Add padding to avoid text cutting off on smaller screens */
  width: 80%; /* Ensure the container width is set to a percentage */
  box-sizing: border-box; /* Include padding in width */
}

.letter {
  display: inline-block;
  font-size: 3em; /* Increase the font size here */
  opacity: 0;
  animation: slideIn 1s forwards;
  font-family: 'Roboto', serif; /* Apply the new font */
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Delay each letter */
.letter:nth-child(1) { animation-delay: 0.2s; }
.letter:nth-child(2) { animation-delay: 0.6s; }
.letter:nth-child(3) { animation-delay: 1s; }
/* Add delay for each letter accordingly */

.text {
  display: inline-block;
  padding: 10px 20px;
  font-size: 2.5em; /* Increase the font size here */
  font-family: 'Roboto', serif; /* Apply the new font */
}

.text:hover {
  color: #ccc; /* Optionally change text color on hover for better visibility */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .home-picture {
    height: 70vh; /* Increase the height for smaller screens */
  }

  .letter {
    font-size: 2.5em; /* Adjust the font size for smaller screens */
  }

  .text {
    font-size: 2em; /* Adjust the font size for smaller screens */
    line-height: 1.2em;
  }
}
