@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.wedding-detail {
  width: 100%;
}

.cover-photo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000066; /* Darken the background image */
  z-index: 1; /* Ensure the shadow is behind the text */
}

.bride-groom-names {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  z-index: 2;
  text-align: center;
  padding: 0 10px; /* Add padding to avoid text cutting off on smaller screens */
  width: 80%; /* Ensure the container width is set to a percentage */
  box-sizing: border-box; /* Include padding in width */
}

.cover-photo {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.h2 {
  display: inline-block;
  padding: 10px 20px;
  font-size: 3em; /* Increase the font size here */
  color: white !important; /* Set text color to white */
  font-family: 'Ailerons', serif; /* Apply the new font */
}

.images-grid {
  column-count: 4; /* Number of columns */
  column-gap: 15px; /* Gap between columns */
  margin-top: 20px;
  padding: 0 20px;
}

.images-grid img {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
  transition: transform 0.3s ease; /* Add smooth transition */
}

.images-grid img:hover {
  transform: scale(1.1); /* Enlarge image on hover */
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2; /* Ensure the shadow is behind the text */
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close-button:hover,
.close-button:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .cover-photo {
    height: 70vh; /* Adjust height for smaller screens */
  }

  .h2 {
    font-size: 2.5em; /* Adjust the font size for smaller screens */
  }

  .images-grid {
    column-count: 2; /* Adjust column count for smaller screens */
  }
}
